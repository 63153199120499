import React, { Component } from "react";
import { map, chunk } from "lodash/fp";
import type { Designer as DesignerType } from "storefront/Designer";
import Designer from "./designer";

type Props = {
  designers: Map<string, Array<DesignerType>>;
};
export default class DropCapList extends Component<Props> {
  renderGroup: (
    arg0: string,
    arg1: Array<DesignerType>,
    arg2: number,
  ) => React.ReactElement<React.ComponentProps<any>, any> = (
    key,
    group,
    index,
  ) => (
    <div className="designer-group" key={`${key}-${index}`}>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'Designer... Remove this comment to see the full error message */}
      {map((d) => <Designer designer={d} key={d.name} />)(group)}
    </div>
  );

  renderDropCapGroup: (
    arg0: any,
  ) => React.ReactElement<React.ComponentProps<any>, any> = (designerGroup) => {
    const [key, group] = designerGroup;
    return (
      <div
        id={`anchor-${key === "#" ? "pound" : key}`}
        className="drop-cap-group"
        key={key}
      >
        <div className="drop-cap playfair">{key}</div>
        <div className="designers">
          {chunk(Math.ceil(group.length / 3), group).map(
            this.renderGroup.bind(this, key),
          )}
        </div>
      </div>
    );
  };

  render() {
    const { designers } = this.props;
    return (
      <div className="designer-list drop-cap-list-wrapper">
        {designers.size === 0 ? (
          <p>No Designers Found</p>
        ) : (
          map(this.renderDropCapGroup)(Array.from(designers))
        )}
      </div>
    );
  }
}
