import { flow, prop, sortBy, filter, groupBy, reverse, slice } from "lodash/fp";
import type { Designer } from "storefront/Designer";
import { isFeatured, nameGroupKey } from "storefront/Designer";

/**
 * @name DesignersApp.Designers.all
 * @description Prepares the given ordered set of designers for the "all" tab of the designers page.
 */
export const all: (arg0: Array<Designer>) => Map<string, Array<Designer>> = (
  designers,
) =>
  new Map(
    Object.entries(
      flow(sortBy(prop("name")), groupBy(nameGroupKey))(designers),
    ),
  );

/**
 * @name DesignersApp.Designers.featured
 * @description Prepares the given ordered set of designers for the "featured" tab of the designers
 * page. Featured designers are those with more than 5 listings.
 */
export const featured: (arg0: Array<Designer>) => Map<string, Array<Designer>> =
  (designers) =>
    new Map(
      Object.entries(
        flow(
          filter(isFeatured),
          sortBy(prop("name")),
          groupBy(nameGroupKey),
        )(designers),
      ),
    );

/**
 * @name DesignersApp.Designers.popular
 * @description Prepares the given ordered set of designers for the "popular" tab of the designers
 * page. Popular designers are the top 100 designers when sorted by number of listings.
 */
export const popular: (arg0: Array<Designer>) => Array<Designer> = (
  designers,
) => flow(sortBy(prop("count")), reverse, slice(0, 101))(designers);
