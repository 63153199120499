import React, { useEffect, useState } from "react";
import designersSearch from "storefront/designersSearch";
import type { Designer } from "storefront/Designer";
import { Resource, loading } from "storefront/lib/Resource";
import designersAll from "../../lib/GrailedAPI/v1/Designers/all";
import * as Designers from "../../containers/designers_app/Designers";
import Spotlight from "./spotlight";
import Alphabet from "./alphabet";
import RefineAndSearch from "./refine_and_search";
import DropCapList from "./drop_cap_list";
import NormalList from "./normal_list";

export type TabName = "featured" | "all" | "popular" | "search";

const getDesigners = async () => {
  const designers = await designersAll();
  return [...new Set(designers)];
};

const DesignerDirectory: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<TabName>("featured");
  const [searchDesigners, setSearchDesigners] = useState<
    Map<string, Array<Designer>>
  >(new Map());
  const [designersResource, setDesignersResource] =
    useState<Resource<Designer[]>>(loading);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [allDesigners, setAllDesigners] = useState<Map<string, Designer[]>>(
    new Map<string, Designer[]>(),
  );
  const [featuredDesigners, setFeaturedDesigners] = useState<
    Map<string, Designer[]>
  >(new Map());
  const [popularDesigners, setPopularDesigners] = useState<Designer[]>([]);

  useEffect(() => {
    getDesigners()
      .then((designers) => {
        setAllDesigners(Designers.all(designers));
        setFeaturedDesigners(Designers.featured(designers));
        setPopularDesigners(Designers.popular(designers));
        setDesignersResource({ type: "Completed", value: designers });
      })
      .catch((e) => {
        setDesignersResource({ type: "Failed", error: e });
      });
  }, []);

  const onTabChange = (tab: TabName) => {
    setSelectedTab(tab);
    setSearchDesigners(new Map());
    setSearchQuery("");
  };

  const onDesignersSearchComplete = (designers: Array<Designer>) => {
    setSearchDesigners(Designers.all(designers));
  };

  const onSearchChange = (query: string) => {
    if (query === "") {
      setSearchDesigners(new Map());
      setSearchQuery(query);
      setSelectedTab("all");
    } else {
      setSearchQuery(query);
      setSelectedTab("search");
      designersSearch(query)
        .then((res) => res.data)
        .then(onDesignersSearchComplete);
    }
  };

  const renderList = () => {
    if (designersResource.type === "Loading") {
      return <div className="designers-loading">Loading designers...</div>;
    }
    switch (selectedTab) {
      case "featured":
        return <DropCapList designers={featuredDesigners} />;
      case "all":
        return <DropCapList designers={allDesigners} />;
      case "popular":
        return <NormalList designers={popularDesigners} />;
      case "search":
        return <DropCapList designers={searchDesigners} />;
      default:
        return null;
    }
  };

  return designersResource.type === "Failed" ? (
    <p>Uh oh, something went wrong.</p>
  ) : (
    <>
      <Spotlight />

      <div className="designer-directory">
        <Alphabet />

        <RefineAndSearch
          searchQuery={searchQuery}
          onSearchChange={onSearchChange}
          selectedTab={selectedTab}
          onTabChange={onTabChange}
        />

        {renderList()}
      </div>
    </>
  );
};

export default DesignerDirectory;
