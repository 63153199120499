import type { Capsule } from "storefront/Capsule";
import { get } from "storefront/GrailedAPI/request";
import type { Pagination } from "storefront/Pagination";

type Response = {
  data: Array<Capsule>;
  metadata: {
    pagination: Pagination;
    totalPages: number;
    totalCount: number;
  };
};

/**
 * @name GrailedAPI.v1.Users.Follows.getFollowedCapsules
 * @param {userId}
 * @return {Promise<Response>} returns a promise that resolves to the capsules
 */
const getFollowedCapsules: (
  arg0: number | string,
  arg1: number,
  arg2: number,
) => Promise<Response> = (userId, page, limit) =>
  get(`/api/users/${userId}/followed_capsules`, {
    page,
    limit,
  });

export default getFollowedCapsules;
