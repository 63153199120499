import React from "react";
import "./DesignersApp.scss";
import DesignerDirectory from "../../components/designers_directory";

const DesignersApp = () => (
  <div className="container">
    <div id="designers" className="index">
      <div className="designer-directory-wrapper _completed">
        <h1 className="-title">Designers</h1>
        <DesignerDirectory />
      </div>
    </div>
  </div>
);

export default DesignersApp;
