import { compose, inRange, toUpper, toLower } from "lodash/fp";

/**
 * @name String.charCodeAt
 */
export const charCodeAt: (arg0: number) => (arg0: string) => number =
  (index) => (str) =>
    str.charCodeAt(index);

/**
 * @name String.charAt
 */
export const charAt: (arg0: number) => (arg0: string) => string =
  (index) => (str) =>
    str.charAt(index);

/**
 * @private
 * @description The character code of the first letter of the English alphabet.
 */
const A = 65;

/**
 * @private
 * @description The character code of the last letter of the English alphabet.
 */
const Z = 90;

/**
 * @name String.startsWithALetter
 * @description Returns true if the given string starts with any character with a character code
 * between 65 (A) and 90 (Z) when that character is converted to uppercase. We add 1 to the upper
 * bound because `inRange` is upper-bound exlusive.
 * More on `inRange` here: https://lodash.com/docs/4.17.11#inRange
 */
export const startsWithALetter: (arg0: string) => boolean = compose([
  inRange(A, Z + 1),
  charCodeAt(0),
  toUpper,
]);

/**
 * @name String.alphabeticalGroupKey
 * @description Determines the key to be used when grouping alphabetically. When the given string
 * starts with a letter, we use that letter as the group key; otherwise we use the non-letter group
 * key, "#".
 */
export const alphabeticalGroupKey: (arg0: string) => string = (str) =>
  startsWithALetter(str) ? charAt(0)(toLower(str)) : "#";
