import React from "react";
import { Designer as DesignerType } from "storefront/Designer";

import useAnalytics from "storefront/hooks/useAnalytics";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";

type Props = {
  designer: DesignerType;
};

const Designer = ({ designer }: Props) => {
  const { track } = useAnalytics();

  const count =
    ("count" in designer && designer.count) ||
    ("listingsCount" in designer && designer.listingsCount) ||
    "";

  return (
    <a
      className="designer"
      href={`/designers/${designer.slug}`}
      onClick={() => track(buttonClicked(designer.name, "designer_index_a_z"))}
    >
      <span>{`${designer.name}`}</span>
      <span className="count">{`(${count})`}</span>
    </a>
  );
};

export default Designer;
