import React, { useEffect, useState } from "react";
import { Designer } from "storefront/Algolia/Designer";
import useAnalytics from "storefront/hooks/useAnalytics";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import fetchByIds from "storefront/Algolia/Designers/fetchByIds";
import ImageV2 from "storefront/components/ImageV2";
import spotlightDesigners from "./spotlightDesigners";

type DesignerWithUrl = Designer & { imageUrl: string };

const findImageUrl = (designer: Designer): string => {
  const spotlightDesigner = spotlightDesigners.find(
    (d) => d.id === designer.id,
  );

  return spotlightDesigner?.image || "";
};

const Spotlight = () => {
  const { track } = useAnalytics();
  const [featuredDesigners, setFeaturedDesigners] =
    useState<Array<DesignerWithUrl> | null>([]);

  useEffect(() => {
    const ids = spotlightDesigners.map((d) => d.id.toString());

    const mergeImageWithAlgoliaDesigner = (
      designer: Designer,
    ): DesignerWithUrl => {
      const designerImage = findImageUrl(designer);

      return { ...designer, imageUrl: designerImage };
    };

    fetchByIds(ids).then((res) => {
      const designersWithImages = res.map((d) =>
        mergeImageWithAlgoliaDesigner(d),
      );

      setFeaturedDesigners(designersWithImages);
    });
  }, []);

  if (!featuredDesigners) return null;

  return (
    <div className="designer-spotlight-wrapper">
      {featuredDesigners.map((d) => (
        <div className="designer-spotlight" key={d.id}>
          <a
            href={`/designers/${d.slug}`}
            className="designer-spotlight-link"
            onClick={() => track(buttonClicked(d.name, "designer_index"))}
          >
            <ImageV2
              className="-logo"
              src={d.imageUrl}
              alt={`${d.name}`}
              width={120}
              height={120}
              priority
            />
          </a>
          <span className="count">{`${d.listingsCount.toLocaleString()} Items`}</span>
        </div>
      ))}
    </div>
  );
};

export default Spotlight;
