import React, { useRef, useEffect } from "react";
import { debounce, isBoolean } from "lodash";

type Props = {
  placeholder: string;
  value: string;
  searchCallback(query: string): void;
  onChange(query: string): void;
  onClear(): void;
  onFocus?: (arg0: void) => void;
  focused?: boolean;
};

const Search = ({
  placeholder,
  value,
  searchCallback,
  onChange,
  onClear,
  onFocus,
  focused,
}: Props) => {
  const inputRef = useRef(null);
  const debouncedSearchCallback = debounce(searchCallback, 300);

  const handleChange = (evt: any) => {
    const query = evt.target.value || "";
    onChange(query);
    debouncedSearchCallback(query);
  };

  const handleFocus = () => {
    if (onFocus) onFocus();
  };

  // focus and blur text input given value of focused
  useEffect(() => {
    if (inputRef.current && isBoolean(focused)) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      if (focused) inputRef.current.focus();
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      else inputRef.current.blur();
    }
  }, [focused, inputRef]);
  return (
    <form className="search" onSubmit={(evt) => evt.preventDefault()}>
      <label>
        <input
          className="search"
          ref={inputRef}
          value={value}
          title={placeholder}
          placeholder={placeholder}
          onChange={handleChange}
          onClick={(evt) => evt.nativeEvent.stopImmediatePropagation()}
          onFocus={handleFocus}
        />
        <div className="search-magnifier" />
        {value.length ? (
          <div className="search-clear" onClick={onClear} />
        ) : null}
      </label>
    </form>
  );
};

export default Search;
