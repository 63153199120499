import React, { Component } from "react";
import classNames from "classnames";
import Search from "storefront/components/Search";
import type { TabName } from "./index";

type Props = {
  selectedTab: TabName;
  onTabChange: (arg0: TabName) => void;
  searchQuery: string;
  onSearchChange: (arg0: string) => void;
};
export default class RefineAndSearch extends Component<Props> {
  renderRefinements: (
    arg0: void,
  ) => Array<React.ReactElement<React.ComponentProps<"h2">, "h2">> = () => {
    const { selectedTab, onTabChange } = this.props;

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

    /* eslint-disable jsx-a11y/click-events-have-key-events */
    return ["featured", "popular", "all"].map((tab) => (
      <h2
        className={classNames({
          active: tab === selectedTab,
        })}
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        onClick={() => onTabChange(tab)}
        key={tab}
      >
        {tab}
      </h2>
    ));
    /* eslint-enable jsx-a11y/click-events-have-key-events */

    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
  };

  render() {
    const { searchQuery, onSearchChange } = this.props;
    return (
      <div className="refine-and-search-wrapper">
        <div className="refinements">{this.renderRefinements()}</div>

        <Search
          searchCallback={() => {}}
          placeholder="Search"
          value={searchQuery}
          onChange={onSearchChange}
          onClear={() => onSearchChange("")}
        />
      </div>
    );
  }
}
