import "marketplaceStylesheets/index.css.scss";
import "whatwg-fetch";
import "storefront/lib/i18n";
import React, { useEffect, lazy } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Provider as AuthenticationProvider } from "storefront/hooks/useAuthentication";
import { Provider as AnalyticsProvider } from "storefront/hooks/useAnalytics";
import FullPageError from "storefront/components/FullPageError";
import Footer from "storefront/components/Page/Footer";
import store, { actions } from "storefront/lib/store";
import onboardingLocalStorageSubscriber from "storefront/Onboarding/localStorageSubscriber";
import SuspenseWithFragment from "storefront/components/SuspenseWithFragment";
import useCurrentDevice from "storefront/hooks/useCurrentDevice";
import GlobalHeader from "storefront/components/Page/Header";
import SiteBanner from "storefront/components/SiteBanner";
import {
  shouldHideTrustStickyFooter,
  trustStickyFooterClosed,
} from "storefront/components/TrustStickyFooter";
import ViolationModal from "storefront/components/ViolationModal";
import { requestWatcher } from "storefront/GrailedAPI/request/request";
import FlashManager from "storefront/lib/flash/FlashManager";
import InitialDataProvider from "storefront/InitialDataProvider";
import { sentryInit, ErrorBoundary } from "../../lib/Sentry";
import { hiring } from "../../lib/console_messages";
import initWebviewDOM from "../../lib/init_webview_dom";
import setUTMParamsCookie from "../../lib/setUTMParamsCookie";
import showFlash from "../../lib/show_flash";
import NavOverlay from "../../components/NavOverlay/NavOverlay";
import Router from "./Router";

import styles from "../Container.module.scss";

sentryInit();

const SignupExperiment = lazy(
  () =>
    import(
      /* webpackChunkName: "SignupExperiment" */
      "storefront/components/SignupExperiment"
    ),
);

const TrustStickyFooter = lazy(
  () =>
    import(
      /* webpackChunkName: "TrustStickyFooter" */
      "storefront/components/TrustStickyFooter"
    ),
);

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Store<Record<string, any>, Actio... Remove this comment to see the full error message
store.subscribe(onboardingLocalStorageSubscriber(store));

const Client = () => {
  // TODO: Make a decision on using this `ConsoleMessages` class thing.
  useEffect(hiring, []);

  // TODO: Refactor parts of `initWebviewDOM` to be declarative.
  useEffect(initWebviewDOM, []);

  // TODO: Refactor `showFlash` and Flash messages in general to be declarative.
  useEffect(showFlash, []);

  useEffect(setUTMParamsCookie, []);

  useEffect(() => {
    // set the current "applied search id" using the window.location
    actions.setCurrentAppliedSearchId();

    // load the current user (using either document-data or making xhr)
    actions.setCurrentUser();
  }, []);

  const device = useCurrentDevice();

  useEffect(() => {
    const flashManager = FlashManager.getInstance();
    const unsubscribe = requestWatcher.subscribe((requestKey) => {
      flashManager.notice(`${requestKey}:  Too many requests!`);
    });

    return unsubscribe;
  }, []);

  return (
    <Provider store={store}>
      <ErrorBoundary fallback={<FullPageError errorCode="unknown" />}>
        <InitialDataProvider>
          <AuthenticationProvider>
            <AnalyticsProvider>
              <div
                id="globalHeader"
                className="GlobalHeaderWrapper"
                style={
                  device === "mobile"
                    ? { position: "sticky", top: 0, zIndex: 220 }
                    : {}
                }
              >
                <GlobalHeader />
              </div>

              <div id="siteBanner">
                <SiteBanner />
              </div>

              <div id="flash" />

              <div id="VacationBannerContainer" />

              <div id="global-modal-container">
                <ViolationModal />
              </div>

              <div id="nav-overlay">
                <NavOverlay />
              </div>

              <ErrorBoundary fallback={<FullPageError errorCode="unknown" />}>
                <div className={styles.container}>
                  <Router />
                </div>
              </ErrorBoundary>

              <footer>
                <Footer />
                <SuspenseWithFragment>
                  <SignupExperiment />
                </SuspenseWithFragment>
              </footer>

              {!trustStickyFooterClosed() && !shouldHideTrustStickyFooter() ? (
                <SuspenseWithFragment>
                  <TrustStickyFooter />
                </SuspenseWithFragment>
              ) : null}
            </AnalyticsProvider>
          </AuthenticationProvider>
        </InitialDataProvider>
      </ErrorBoundary>
    </Provider>
  );
};

const appEl = window.document.getElementById("app");
if (!appEl) throw new Error("Could not find #app element");

const root = createRoot(appEl);
root.render(<Client />);
