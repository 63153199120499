import { Id } from "storefront/lib/Id";

type SpotlightDesigner = {
  id: Id;
  name: string;
  image: string;
};

// When we query algolia, we are querying by IDs. Locally,
// this means that id 39 is not Raf Simons.
const spotlightDesigners: Array<SpotlightDesigner> = [
  {
    name: "Nike",
    id: 30,
    image:
      "https://media-Assets.grailed.com/prd/misc/YW3TJY0FQHBRRPCBVIZPXJAOTJFMUTOZJDNGCW",
  },
  {
    name: "Supreme",
    id: 6,
    image:
      "https://media-assets.grailed.com/prd/misc/NDLEU3ZMN2CLPMJGT52PTZUBO-BB7LUYX9BMFG",
  },
  {
    name: "Rick Owens",
    id: 32,
    image:
      "https://media-assets.grailed.com/prd/misc/TNDBL06XZBGZL3KCFFXZGCOQHTX7YJY2CQ08UA",
  },
  {
    name: "Bape",
    id: 38,
    image:
      "https://media-assets.grailed.com/prd/misc/VCVILMVFFXOWVFRWMM-FYT4WQAWAYXEKQCRNFA",
  },
  {
    name: "Chrome Hearts",
    id: 405,
    image:
      "https://media-assets.grailed.com/prd/misc/QFQ_3RAS-5XN6CKOLN4J2OORBNMLDTDGDRBXKW",
  },
  {
    name: "Balenciaga",
    id: 136,
    image:
      "https://media-assets.grailed.com/prd/misc/HTYB_TTQVVZ4AVIYFOBIE4LXLPW_POPMFIRB7A",
  },
  {
    name: "Prada",
    id: 303,
    image:
      "https://media-assets.grailed.com/prd/misc/4XIHIPHUL3_RAVWO86VSODQC3WJERPLMED-0BW",
  },
  {
    name: "Gucci",
    id: 79,
    image:
      "https://media-assets.grailed.com/prd/misc/IIS1SAODWPQUE-EKJXW0YC7DM3B2109KPWW2HW",
  },
];

export default spotlightDesigners;
