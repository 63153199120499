import { prop, compose, gt, __ } from "lodash/fp";
import { Designer as AlgoliaDesigner } from "storefront/Algolia/Designer";
import { alphabeticalGroupKey } from "./lib/String";
import { Id, emptyId } from "./lib/Id";
import { Department } from "./Department";

export type GrailedAPIDesigner = {
  id: Id;
  name: string;
  slug: string;
  departments: Array<Department>;
  logoUrl: string | null;
};

export const empty: GrailedAPIDesigner = {
  id: emptyId,
  name: "",
  slug: "",
  departments: [],
  logoUrl: null,
};

/**
 * Generic designer union -- pls use a more specific type unless shared
 */
export type Designer = GrailedAPIDesigner | DesignerCached | AlgoliaDesigner;

/** Barely used type, likely only on designers index */
export type DesignerCached = Pick<
  GrailedAPIDesigner,
  "id" | "name" | "slug" | "departments"
> & {
  count: number;
};

export const DESIGNER_SEPARATOR = " \u00D7 ";

/**
 * @name Designer.countGreaterThan
 * @description Given a number to compare against, returns true if the given designer's count is
 * greater than the comparator.
 */
export const countGreaterThan: (arg0: number) => (arg0: Designer) => boolean = (
  comparator,
) => compose([gt(__, comparator), prop("count")]);

/**
 * @name Designer.isFeatured
 * @description A designer is considered "featured" when it has a count greater than 5.
 */
export const isFeatured = countGreaterThan(5);

/**
 * @private
 * @description Given a function that takes a designer and returns a string, uses that returned
 * string to determine the alphabetical group key for a designer.
 */
const alphabeticalGroupKeyWith: (
  arg0: (arg0: Designer) => string,
) => (arg0: Designer) => string = (fn) => compose([alphabeticalGroupKey, fn]);

/**
 * @name Designer.nameGroupKey
 * @description Given a designer, returns the key for grouping that designer based on the
 * designer's name.
 */
export const nameGroupKey = alphabeticalGroupKeyWith(prop("name"));

/**
 * @name Designer.formatDesignerNames
 * @description Given one or more designers, retrieves and formats their name. If more than
 * one designer it joins them with multiplication symbol. If one designer just returns their name.
 */
export const formatDesignerNames = (
  designers: Array<Designer> | Array<GrailedAPIDesigner>,
): string =>
  designers
    .map((designer: Designer | GrailedAPIDesigner) => designer.name)
    .sort()
    .join(DESIGNER_SEPARATOR);
