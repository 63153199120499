import React from "react";
import type { Designer as DesignerType } from "storefront/Designer";
import Designer from "./designer";

type Props = {
  designers: Array<DesignerType>;
};
export default function NormalList({ designers }: Props) {
  return (
    <div className="designer-list normal-list-wrapper">
      {designers.map((d) => (
        <Designer designer={d} key={d.slug} />
      ))}
    </div>
  );
}
