import algoliasearch, { SearchClient, SearchIndex } from "algoliasearch";
import { Designer } from "storefront/Designer";
import { camelize } from "camelscore";
import { initPromise } from "storefront/lib/i18n";

type FormattedResult = {
  data: Array<Designer>;
  metadata: {};
};
type Options = {
  page?: number;
  hitsPerPage?: number;
  filters?: string;
  facetFilters?: Array<string>;
  numericFilters?: string;
};
type PublicConfig = Record<string, any>;
type AlgoliaConfig = Record<string, any>;

const getDesignerIndex = ({ indexes }: AlgoliaConfig): Promise<string> =>
  Promise.resolve(indexes.designers[0].value);

const createClient = ({
  app_id,
  public_search_key,
}: AlgoliaConfig): Promise<SearchClient> =>
  Promise.resolve(algoliasearch(app_id, public_search_key));

const createIndex = ({ algolia }: PublicConfig): Promise<SearchIndex> =>
  Promise.all([getDesignerIndex(algolia), createClient(algolia)]).then(
    ([designerIndex, client]) => client.initIndex(designerIndex),
  );

const createFacetFilters = (): Promise<Array<string>> =>
  initPromise.then((t) => [`marketplace:${t("MARKETPLACE_ID")}`]);

const buildOptions = (
  strata: string,
  hitsPerPage: number,
  filters: string,
): Promise<Options> =>
  createFacetFilters().then((facetFilters) => ({
    page: 0,
    hitsPerPage,
    filters,
    facetFilters,
    numericFilters:
      strata !== "all" ? `listings_by_strata_count.${strata}>0` : undefined,
  }));

const format = ({ hits }: Record<string, any>): FormattedResult => ({
  data: camelize(hits),
  metadata: {},
});

const designersSearch = (
  query = "",
  filters = "",
  strata = "all",
  hitsPerPage = 20,
): Promise<FormattedResult> =>
  Promise.all([
    createIndex(window.PUBLIC_CONFIG),
    buildOptions(strata, hitsPerPage, filters),
  ])
    .then(([index, options]) => index.search(query, options))
    .then(format);

export default designersSearch;
