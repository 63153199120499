import type { IndexedList } from "../lib/IndexedList";
import type { Index as Algolia$Index } from "../Algolia/IndexType";
import type { Strata } from "../Strata";
import type { Country } from "../Country";
import type { Badge } from "../Badge";

type Algolia = {
  appId: string;
  indexes: IndexedList<Array<Algolia$Index>>;
  publicSearchKey: string;
  publicBrowseKey: string;
  publicQueryKey: string;
};
// This is the config state in our redux store
export type Config = {
  algolia: Algolia;
  algoliaIndexes: Array<Algolia$Index>;
  badges: Array<Badge>;
  countries: Array<Country>;
  facebookAppId: number;
  stratas: Array<Strata>;
};
export const empty: Config = {
  // @ts-expect-error ts-migrate(2739) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
  algolia: {},
  algoliaIndexes: [],
  badges: [],
  countries: [],
  facebookAppId: 0,
  stratas: [],
};
