import React from "react";
import { compose, map, split } from "lodash/fp";

const ALPHABET = "#abcdefghijklmnopqrstuvwxyz";

const scrollTo: (
  arg0: string,
) => (arg0: React.MouseEvent<HTMLSpanElement>) => void =
  (letter) => (_event) => {
    const id = `anchor-${letter === "#" ? "pound" : letter}`;
    const sectionElement = window.document.getElementById(id);
    if (!sectionElement) return;
    const { top } = sectionElement.getBoundingClientRect();
    window.scrollTo({
      top: top - 120,
      behavior: "smooth",
    });
  };

const Letter: (
  arg0: string,
) => React.ReactElement<React.ComponentProps<"span">, "span"> = (letter) => (
  <span onClick={scrollTo(letter)} key={letter}>
    {letter}
  </span>
);

const Alphabet: (arg0: {}) => React.ReactElement<
  React.ComponentProps<"div">,
  "div"
> = () => (
  <div className="alphabet playfair">
    {compose([map(Letter), split("")])(ALPHABET)}
  </div>
);

export default Alphabet;
